export default {
  en: {
    actions: {
      add: 'Add',
      addToCalendar: 'Add to calendar',
      accept: 'Accept',
      addFile: 'Add file',
      addImage: 'Add image',
      clear: 'Clear',
      clone: 'Clone',
      continue: 'Continue',
      create: 'Create',
      delete: 'Delete',
      downloadReport: 'Download Report',
      filter: 'Filter',
      hideFilters: 'Hide filters',
      hideReactions: 'Disable Emotions',
      showReactions: 'Enable Emotions',
      remove: 'Remove',
      saveChanges: 'Save changes',
      save: 'Save',
      search: 'Search',
      filterKeyword: 'Filter keywords',
      cancel: 'Cancel',
      moreOptions: 'More options',
      logout: 'Log out',
      editPhoto: 'Edit Photo',
      send: 'Send',
      upload: 'Upload',
      returnTo: 'Return to',
      edit: 'Edit',
      discardChanges: 'Discard changes',
    },
    navigation: {
      events: 'Events',
      team: 'My Team',
      greeting: 'Hello, {{ user }}',
      userMenu: 'User menu',
      myAccount: 'My account',
      languageSelectionMenu: 'Language selection menu',
      help: 'Help',
      notifications: 'Notifications',
      navigation: 'Navigation',
      actions: 'Actions',
    },
    error404: {
      title: 'We couldnt find the page you were looking for',
      subtitle: 'Try another option using the sidebar.',
    },
    title: {
      saved: 'Saved!',
      error: 'Error',
      announcement: 'Announcement!',
      reminder: 'Reminder',
    },
    message: {
      error: 'We could not save your changes, please try again.',
      success: 'Your changes have been saved successfully.',
    },
    formError: {
      enterValidEmail: 'Enter a valid email.',
      requiredField: 'The field is required.',
      invalidToken: 'The token you entered is invalid.',
      invalidEmail: 'The email you provided is invalid.',
      invalidPassword:
        'Use at least one upper case letter, one lowercase letter, one number and 8 characters length',
      errorPasswordMatch: "The passwords don't match",
      tooLong: 'Max {{ limit }} characters',
      invalidSize: 'Invalid size',
    },
    forms: {
      ticketType: 'Ticket type',
      notRegisteredLink: 'Register here',
      optional: 'Optional',
      signature: 'Signature',
      firstName: 'First name',
      name: 'Name',
      lastName: 'Last name',
      telephone: 'Phone',
      country: 'Country',
      jobTitle: 'Job title',
      company: 'Company',
      region: 'Region',
      city: 'City',
      photo: 'Photo',
      keywords: 'Keywords',
      email: 'Email',
      accessToken: 'Access token',
      validateToken: 'Validate token',
      login: 'Login',
      error: 'Error',
      createTicket: 'Create ticket type',
      createCategory: 'Create category',
      password: 'Password',
      confirmPassword: 'Confirm Password',
      createDate: 'Date created',
    },
    formPlaceHolder: {
      password: '***********',
    },
    dates: {
      localTimeIs: 'Local time is',
      startDate: 'Start date',
      endDate: 'End date',
      from: 'From',
      to: 'To',
    },
    languages: {
      language: 'Language',
      en: 'English',
      es: 'Spanish',
      fr: 'French',
      it: 'Italian',
      de: 'German',
      ru: 'Russian',
    },
    status: {
      title: 'Status',
      saving: 'Saving',
      completed: 'Completed',
      loading: 'Loading...',
    },
    info: {
      logoAspectRatio:
        'For a better display, make sure the logo has a 16:9 aspect ratio',
      forgotPassword: 'Please enter the email you use to login to Eventtia',
      welcomeToEventtiaTitle: 'Welcome to Eventtia!',
      successEventCreatedDescription:
        'Before you get started, you can check out our <1>Help Center</1> to get a guided tour of the platform or continue to explore on your own.',
      didYouForgetPassword: 'Did you forget your password?',
      newUserPassword: 'Set your first password',
      passwordGuide:
        'Please Enter a password that has an uppercase, lowercase, a number, and is at least 8 characters long',
    },
    speakers: {
      speakers: 'Speakers',
    },
    sponsors: {
      sponsoredBy: 'Sponsored by',
      poweredBy: 'Powered by',
    },
    timer: {
      before:
        'This meeting hasn’t started yet!\nTake a seat and relax, you have {{time}} before it begins.',
      ended: 'Your meeting with {{otherParticipant}} ended {{when}}',
      remaining: 'remaining',
    },
    notes: {
      title: 'Notes',
    },
    chat: {
      title: 'Chat',
    },
    prepositions: {
      at: 'at',
    },
    qa: {
      sendAQuestion: 'Send a question...',
    },
    confirmation: {
      yes: 'Yes',
    },
    reactions: {
      reactions: 'Reactions',
    },
    ariaLabel: {
      forgotPasswordForm: 'Forgot Password Form',
      ChangePasswordForm: 'Change Password Form',
    },
  },
  es: {
    actions: {
      add: 'Agregar',
      addToCalendar: 'Agregar al calendario',
      accept: 'Aceptar',
      addFile: 'Agregar un archivo',
      addImage: 'Agregar una imagen',
      clear: 'Limpiar',
      clone: 'Clonar',
      continue: 'Continuar',
      create: 'Crear',
      delete: 'Eliminar',
      downloadReport: 'Descargar reporte',
      filter: 'Filtrar',
      hideFilters: 'Ocultar filtros',
      hideReactions: 'Deshabilitar emociones',
      showReactions: 'Habilitar emociones',
      remove: 'Eliminar',
      saveChanges: 'Guardar cambios',
      save: 'Guardar',
      search: 'Buscar',
      cancel: 'Cancelar',
      moreOptions: 'Más opciones',
      logout: 'Cerrar sesión',
      editPhoto: 'Editar foto',
      send: 'Enviar',
      upload: 'Subir',
    },
    error404: {
      title: 'No encontramos la página que tratas de acceder.',
      subtitle: 'Intenta otra opción en la barra lateral.',
    },
    title: {
      saved: 'Saved!',
      error: 'Error',
      announcement: '¡Anuncio!',
      reminder: 'Recordatorio',
    },
    message: {
      error: 'We could not save your changes, please try again.',
      success: 'Your changes have been saved successfully.',
    },
    formError: {
      enterValidEmail: 'Ingresa un email válido',
      requiredField: 'El campo es obligatorio.',
      invalidToken: 'El token que has ingresado es inválido.',
      invalidEmail: 'El correo ingresado es inválido.',
      tooLong: 'Máximo {{ limit }} caracteres',
    },
    forms: {
      optional: 'Opcional',
      notRegisteredLink: ' Regístrate aquí',
      signature: 'Firma',
      firstName: 'Nombre',
      lastName: 'Apellido',
      telephone: 'Teléfono',
      country: 'Pais',
      jobTitle: 'Titulo laboral',
      company: 'Compañía',
      region: 'Región',
      city: 'Ciudad',
      photo: 'Foto',
      keywords: 'Palabras clave',
      email: 'Correo',
      accessToken: 'Código de acceso',
      validateToken: 'Validar token',
      logIn: 'Ingresar',
      error: 'Error',
      createTicket: 'Crear tipo de ticket',
      createCategory: 'Crear categoria',
    },
    dates: {
      localTimeIs: 'La hora local es',
      startDate: 'Fecha de inicio',
      endDate: 'Fecha de fin',
      range: 'Rango de fechas',
      from: 'Desde',
      to: 'Hasta',
    },
    languages: {
      language: 'Idioma',
      en: 'Inglés',
      es: 'Español',
      fr: 'Frances',
    },
    status: {
      title: 'Estado',
      saving: 'Guardando',
      completed: 'Completada',
      loading: 'Cargando...',
    },
    info: {
      logoAspectRatio:
        'Para una mejor visualización asegúrate que el logo tenga una proporción de 16:9',
    },
    speakers: {
      speakers: 'Ponentes',
    },
    sponsors: {
      sponsoredBy: 'Patrocinado por',
      poweredBy: 'Powered by',
    },
    timer: {
      before:
        'Ésta reunión aún no ha comenzado!\nToma asiento y relájate, tienes {{time}} antes de que comience',
      ended: 'Tu reunión con {{otherParticipant}} terminó {{when}}',
      remaining: 'restante',
    },
    notes: {
      title: 'Notas',
    },
    chat: {
      title: 'Chat',
    },
    prepositions: {
      at: 'en',
    },
    qa: {
      sendAQuestion: 'Envia una pregunta...',
    },
    confirmation: {
      yes: 'Si',
    },
    reactions: {
      reactions: 'Reacciones',
    },
  },
  fr: {
    actions: {
      add: 'Ajouter',
      addToCalendar: 'Ajouter au calendrier',
      accept: 'Accepter',
      addFile: 'Ajouter un fichier',
      addImage: 'Ajouter une image',
      clear: 'Effacer',
      clone: 'Cloner',
      continue: 'Continuer',
      create: 'Créer',
      delete: 'Supprimer',
      filter: 'Filtrer',
      hideFilters: 'Cacher les filtres',
      hideReactions: "Désactiver l'émotion",
      showReactions: 'Permettre les émotions',
      remove: 'Éliminer',
      saveChanges: 'Sauvegarder les changements',
      save: 'Sauvegarder',
      search: 'Rechercher',
      cancel: 'Annuler',
      moreOptions: "Plus d'options",
      logout: 'Se déconnecter',
      editPhoto: 'Éditer la photo',
      send: 'Envoyer',
      upload: 'Télécharger',
    },
    error404: {
      title: "Nous n'avons pas trouvé la page que vous recherchiez",
      subtitle: 'Essayez une autre option en utilisant la barre latérale.',
    },
    title: {
      saved: 'Saved!',
      error: 'Error',
      announcement: 'Annonce!',
      reminder: 'Rappel',
    },
    message: {
      error: 'We could not save your changes, please try again.',
      success: 'Your changes have been saved successfully.',
    },
    formError: {
      enterValidEmail: 'Entrez un courriel valide',
      requiredField: 'Le champ est obligatoire.',
      invalidToken: "Le jeton que vous avez saisi n'est pas valable.",
      invalidEmail: "Le courrier entré n'est pas valide",
      tooLong: 'Maximum {{ limit }} caractères',
    },
    forms: {
      optional: 'Optional',
      notRegisteredLink: ' Inscrivez-vous ici',
      signature: 'Signature',
      firstName: 'Prénom',
      lastName: 'Nom de famille',
      telephone: 'Téléphone',
      country: 'Pays',
      jobTitle: 'Fonction',
      company: 'Entreprise',
      region: 'Région',
      city: 'Cité',
      photo: 'Photo',
      keywords: 'Mots clés',
      email: 'Email',
      accessToken: "Code d'accès",
      validateToken: 'Valider le code',
      logIn: 'Se connecter',
      error: 'Erreur',
      createTicket: 'Créer un type de billet',
      createCategory: 'Créer une catégorie',
    },
    dates: {
      localTimeIs: "L'heure locale est",
      startDate: 'Date de début',
      endDate: 'Date de fin',
      range: 'Plage de dates',
      from: 'de',
      to: 'à',
    },
    languages: {
      language: '',
      en: 'Anglais',
      es: 'Espagnol',
      fr: 'Français',
    },
    status: {
      title: 'Statut',
      saving: 'Sauver',
      completed: 'Complété',
    },
    info: {
      logoAspectRatio:
        'Pour une meilleure visualisation, assurez-vous que le logo a un rapport de 16:9',
    },
    speakers: {
      speakers: 'Intervenants',
    },
    sponsors: {
      sponsoredBy: 'Sponsorisé par',
      poweredBy: 'Powered by',
    },
    timer: {
      before:
        "Cette réunion n'a pas encore commencé!\nDétendez-vous, vous avez {{time}} avant de commencer",
      ended: "Votre réunion avec {{otherParticipant}} s'est terminée {{when}}",
      remaining: 'restant',
    },
    notes: {
      title: 'Notes',
    },
    chat: {
      title: 'Chat',
    },
    prepositions: {
      at: 'chez',
    },
    qa: {
      sendAQuestion: 'Envoyer une question...',
    },
    confirmation: {
      yes: 'Oui',
    },
    reactions: {
      reactions: 'Réactions',
    },
  },
};
