import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  useLocation,
  useRoutes,
  useParams,
  useNavigate,
} from 'react-router-dom';
import moment from 'moment-timezone';
import Loader from 'eventtia-ui-components/lib/Loader';
import callApi from '../actions/callApi';
import { loggedIn } from '../helpers/auth';
import i18n, { availableLanguages } from '../locales';

import { loggedOutRoutesConfig, loggedInRoutesConfig } from '../routes/config';

const App = ({
  fetchingCurrentUser,
  currentUserFetched,
  loggedInSuccessfully,
  callApi: dispatchCallApi,
  activatedSuccessfully,
}) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { locale } = useParams();
  const isLoggedIn = loggedIn() || currentUserFetched;
  const appRoutes = useRoutes(
    isLoggedIn ? loggedInRoutesConfig : loggedOutRoutesConfig
  );

  useEffect(() => {
    if (availableLanguages.includes(locale)) {
      moment.locale(locale);
      i18n.changeLanguage(locale);
    } else navigate('/en');
  }, [locale, navigate]);

  useEffect(() => {
    if (loggedIn()) {
      dispatchCallApi('currentUser');
      dispatchCallApi('accountDetails');
    }
  }, [dispatchCallApi, loggedInSuccessfully, activatedSuccessfully]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  if (fetchingCurrentUser) return <Loader loading variant="absolute" />;

  return appRoutes;
};

App.propTypes = {
  fetchingCurrentUser: PropTypes.bool.isRequired,
  currentUserFetched: PropTypes.bool.isRequired,
  loggedInSuccessfully: PropTypes.bool.isRequired,
  activatedSuccessfully: PropTypes.bool.isRequired,
  callApi: PropTypes.func.isRequired,
};

const mapStateToProps = ({
  fetchStatus: {
    currentUser: {
      isFetching: fetchingCurrentUser,
      success: currentUserFetched,
    },
    login: { success: loggedInSuccessfully },
    activateAccount: { success: activatedSuccessfully },
  },
}) => ({
  fetchingCurrentUser,
  currentUserFetched,
  loggedInSuccessfully,
  activatedSuccessfully,
});

export default connect(mapStateToProps, { callApi })(App);
