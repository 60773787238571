export default {
  en: {
    login: 'Sign in',
    ariaLabel: 'Sign In Form',
    forms: {
      signIn: 'Sign in',
      password: 'Password',
      username: 'Username',
    },
    message: {
      noAccount: 'New on Eventtia? ',
      forgotPassword: 'Forgot Password? ',
    },
    signUp: 'Sign up',
    actions: {
      clickHere: 'Click here',
    },
  },
  es: {
    forms: {
      signIn: 'Sign in',
      password: 'Password',
      username: 'Username',
    },
  },
  fr: {},
  de: {},
};
