import Joi from 'joi';
import { ATTENDANCE_MODE, PAYPAL, PAYU, STRIPE } from './constants';
import { passwordMinimumRequirementsRegex } from './stringValidations';
import { webSitePattern } from './strings';
import fieldTypeLabels, { FIELDS } from './fieldTypes';

const fieldTypes = Object.keys(fieldTypeLabels);

// TODO autogenerate this array using routes file
const { VIRTUAL, PHYSICAL } = ATTENDANCE_MODE;

const editUser = Joi.object({
  id: Joi.string().required(),
  firstName: Joi.string().required(),
  lastName: Joi.string().required(),
  email: Joi.string().required(),
  accountName: Joi.string().required(),
});

const changePasswordSchema = Joi.object({
  currentPassword: Joi.string().required(),
  newPassword: Joi.string()
    .pattern(new RegExp(passwordMinimumRequirementsRegex))
    .required(),
  newPasswordConfirmation: Joi.string()
    .valid(Joi.ref('newPassword'))
    .required(),
});

const attendeeCreateSchema = Joi.object({
  firstName: Joi.string().required(),
  ticketType: Joi.string().required(),
  lastName: Joi.string().required(),
  email: Joi.string()
    .email({ tlds: { allow: false } })
    .required(),
  phone: Joi.string().required(),
  photo: Joi.array().optional(),
  fieldsData: Joi.object().pattern(/^/, Joi.any()),
  // category: Joi.string().optional(),
});

const login = Joi.object({
  email: Joi.string()
    .email({ tlds: { allow: false } })
    .required(),
  password: Joi.string().required(),
});
const signUp = Joi.object({
  firstName: Joi.string().required(),
  lastName: Joi.string().required(),
  company: Joi.string().required(),
  email: Joi.string()
    .email({ tlds: { allow: false } })
    .required(),
  phone: Joi.string().required(),
  password: Joi.string()
    .pattern(new RegExp(passwordMinimumRequirementsRegex))
    .required(),
  confirmPassword: Joi.string().valid(Joi.ref('password')),
});
const forgotPassword = Joi.object({
  email: Joi.string()
    .email({ tlds: { allow: false } })
    .required(),
});
const changePassword = Joi.object({
  password: Joi.string()
    .pattern(new RegExp(passwordMinimumRequirementsRegex))
    .required(),
  confirmPassword: Joi.string().valid(Joi.ref('password')).required(),
});
const sharedCreateEventShape = {
  name: Joi.string().max(200).required(),
  logo: Joi.array(),
  defaultLanguage: Joi.string().required(),
  attendanceMode: Joi.string().required(),
  location: Joi.when('attendanceMode', {
    is: PHYSICAL,
    then: Joi.object().required(),
  }),
  timezone: Joi.when('attendanceMode', {
    is: VIRTUAL,
    then: Joi.string().required(),
  }),
};

const createEventShape = {
  startDate: Joi.string().isoDate().required(),
  endDate: Joi.string().isoDate().required(),
  category: Joi.string().required(),
};

const availableLanguagesShape = {
  default: Joi.string(),
  available: Joi.array(),
};

const createTemplateShape = {
  logo: Joi.array(),
  defaultLanguage: Joi.string().required(),
  timeFormat: Joi.string().required(),
  dateFormat: Joi.string().required(),
  googleAnalyticsTrackingCode: Joi.string().allow('').allow(null).optional(),
  googleTagManagerCode: Joi.string().allow('').allow(null).optional(),
};

const sharedEditEventSchema = {
  description: Joi.string().max(2500).allow('').allow(null),
  banner: Joi.array().optional(),
  defaultLanguage: Joi.string().required(),
  availableLanguages: Joi.object(availableLanguagesShape).optional(),
  currency: Joi.string().required(),
};

const createEvent = Joi.object({
  ...sharedCreateEventShape,
  ...createEventShape,
});
const editEvent = createEvent.append({
  ...createTemplateShape,
  ...sharedEditEventSchema,
});
const createTemplate = Joi.object({
  ...sharedCreateEventShape,
  ...createTemplateShape,
});
const editTemplate = createTemplate.append(sharedEditEventSchema);

const createTicketTypeSchema = Joi.object({
  name: Joi.string().required(),
  noLimit: Joi.bool(),
  limit: Joi.number().required(),
  description: Joi.string().required(),
  price: Joi.number().required().allow(''),
});

const editTicketTypeSchema = Joi.object({
  name: Joi.object().pattern(/^/, Joi.string().allow('')).required(),
  noLimit: Joi.bool(),
  limit: Joi.number().required(),
  description: Joi.object().pattern(/^/, Joi.string()).required(),
  price: Joi.number().required().allow(''),
});

const ticketTypeAdvancedOptions = Joi.object({
  confirmationRequired: Joi.bool(),
  allowPublicRegistration: Joi.bool(),
  validIfNoPayments: Joi.bool(),
  printingWithoutPayment: Joi.bool(),
});

const createPricingTier = Joi.object({
  price: Joi.alternatives().try(Joi.number(), Joi.string()),
  startDate: Joi.string().isoDate().required(),
  endDate: Joi.string().isoDate().required(),
});

const fieldsSchema = Joi.object({
  id: Joi.string(),
  name: Joi.object().pattern(/^/, Joi.string()).required(),
  type: Joi.string()
    .valid(...fieldTypes)
    .required(),
  values: Joi.array()
    .items(Joi.object().pattern(/^/, Joi.string()))
    .when('type', { is: FIELDS.SINGLE_SELECT, then: Joi.required() })
    .when('type', { is: FIELDS.MULTIPLE_SELECT, then: Joi.required() }),
  settings: Joi.object({
    availablePublicListings: Joi.bool(),
    display: Joi.bool().allow(null),
    filterable: Joi.bool().allow(null),
    controlType: Joi.string().valid('radio', 'dropdown_list', 'check_boxes'),
    termsMode: Joi.string().valid('url', 'file'),
    termsUrl: Joi.when('termsMode', {
      is: 'url',
      then: Joi.string().pattern(webSitePattern, 'Valid URL').required(),
    }),
  })
    .when('type', {
      is: FIELDS.SINGLE_SELECT,
      then: Joi.object({ controlType: Joi.required() }),
    })
    .when('type', {
      is: FIELDS.MULTIPLE_SELECT,
      then: Joi.object({ controlType: Joi.required() }),
    })
    .when('type', {
      is: FIELDS.TERMS,
      then: Joi.object({ termsMode: Joi.required() }),
    }),
  validations: Joi.object({
    validationType: Joi.string().valid('numbers', 'letters', 'telephone'),
    maximumCharacters: Joi.number().min(0),
    required: Joi.bool().allow(null),
    lowerBound: Joi.string().isoDate(),
    upperBound: Joi.string().isoDate(),
  })
    .when('type', {
      is: FIELDS.TEXT,
      then: Joi.object({ validationType: Joi.required() }),
    })
    .when('type', {
      is: FIELDS.TEXT_AREA,
      then: Joi.object({ maximumCharacters: Joi.required() }),
    })
    .when('type', {
      is: FIELDS.DATE,
      then: Joi.object({ lowerBound: Joi.required() }),
    })
    .when('type', {
      is: FIELDS.DATE,
      then: Joi.object({ upperBound: Joi.required() }),
    }),
  order: Joi.number(),
  entityId: Joi.number(),
  default: Joi.bool(),
  repeatValue: Joi.any(),
  // allowedValues: Joi.string().allow('', null),
  file: Joi.array()
    .items(Joi.object())
    .when('settings.termsMode', { is: 'file', then: Joi.required() }),
  includeTime: Joi.bool()
    .allow(null)
    .when('type', { is: FIELDS.DATE, then: Joi.required() }),
});

const payments = Joi.object({
  currency: Joi.string().required(),
  vatAlias: Joi.string().allow('').optional(),
  vatValue: Joi.number().min(0).precision(2).optional(),
  paymentMethod: Joi.string().allow(null).optional(),
  stripeSecretApiKey: Joi.when('paymentMethod', {
    is: STRIPE,
    then: Joi.string().required(),
  }),
  stripePublishableApiKey: Joi.when('paymentMethod', {
    is: STRIPE,
    then: Joi.string().required(),
  }),
  payUApiKey: Joi.when('paymentMethod', {
    is: PAYU,
    then: Joi.string().required(),
  }),
  payUApiLogin: Joi.when('paymentMethod', {
    is: PAYU,
    then: Joi.string().required(),
  }),
  payUMerchantId: Joi.when('paymentMethod', {
    is: PAYU,
    then: Joi.string().required(),
  }),
  payUAccountId: Joi.when('paymentMethod', {
    is: PAYU,
    then: Joi.string().required(),
  }),
  payUTestMode: Joi.when('paymentMethod', { is: PAYU, then: Joi.string() }),
  eventPaypalProductionKey: Joi.when('paymentMethod', {
    is: PAYPAL,
    then: Joi.string().required(),
  }),
  eventPaypalSandboxKey: Joi.when('paymentMethod', {
    is: PAYPAL,
    then: Joi.string().required(),
  }),
  eventPaypalTestMode: Joi.when('paymentMethod', {
    is: PAYPAL,
    then: Joi.string(),
  }),
});

const createUser = Joi.object({
  firstName: Joi.string().required(),
  lastName: Joi.string().required(),
  email: Joi.string()
    .email({ tlds: { allow: false } })
    .required(),
  phone: Joi.string(),
  accessToAllEvents: Joi.bool(), // TODO: make required once it's supported
});

const attendeeFiltersSchema = Joi.object({
  firstName: Joi.string().allow(''),
  lastName: Joi.string().allow(''),
  email: Joi.string()
    .email({ tlds: { allow: false } })
    .allow(''),
  attendeeTypeId: Joi.number().allow(''),
  lowerBound: Joi.string().isoDate().allow(''),
  upperBound: Joi.string().isoDate().allow(''),
});

const filterActionsSchema = Joi.object({
  startLowerBound: Joi.string().isoDate().allow(''),
  startUpperBound: Joi.string().isoDate().allow(''),
  endLowerBound: Joi.string().isoDate().allow(''),
  endUpperBound: Joi.string().isoDate().allow(''),
});

const emailCreateSchema = Joi.object({
  name: Joi.string().required(),
  description: Joi.string().required(),
  availableFor: Joi.string().required(),
  template: Joi.string().required(),
});

const emailSettingsSchema = Joi.object({
  name: Joi.string().required(),
  email: Joi.string().required(),
});

const createCategorySchema = Joi.object({
  name: Joi.string().required(),
  description: Joi.string().allow(''),
});

const widgetRegistration = Joi.object({
  logo: Joi.array(),
  mainColor: Joi.string(),
  headerImage: Joi.array(),
  css: Joi.string().allow(''),
  headerTextColor: Joi.string(),
  secondaryColor: Joi.string(),
  accessButton: Joi.object().pattern(/^/, Joi.string().allow('')).required(),
});

const createRoleSchema = Joi.object({
  name: Joi.string().allow('').required(),
}).unknown(true);

export default {
  login,
  signUp,
  forgotPassword,
  changePassword,
  editUser,
  changePasswordSchema,
  createCategorySchema,
  createEvent,
  editEvent,
  createTemplate,
  editTemplate,
  payments,
  createTicketTypeSchema,
  editTicketTypeSchema,
  ticketTypeAdvancedOptions,
  fieldsSchema,
  attendeeCreateSchema,
  attendeeFiltersSchema,
  createPricingTier,
  createUser,
  filterActionsSchema,
  emailCreateSchema,
  emailSettingsSchema,
  widgetRegistration,
  createRoleSchema,
};
