export default {
  en: {
    title: 'Events',
    templates: 'Templates',
    eventSettings: 'Event settings',
    createTemplateForm: 'Create template form',
    createEventForm: 'Create event form',
    templateSettings: 'Template settings',
    sidebar: {
      myEvents: 'My events',
      myEvent: 'My event',
      dashboard: 'Dashboard',
      modules: 'Modules',
    },
    actions: {
      createEvent: 'Create event',
      createTemplate: 'Create template',
      createAnotherTemplate: 'Create another template',
      copyTemplate: 'Copy template',
      copyEvent: 'Copy event',
      deleteEvent: 'Delete event',
      deleteTemplate: 'Delete template',
      addLogo: 'Add logo to an event',
      editLogo: 'Edit logo',
      createEventTicket: 'Create event Ticket',
      editTemplate: 'Edit template',
      editEventSettings: 'Edit event settings',
      copy: 'Copy',
    },
    messages: {
      errors: {
        creatingEvent: 'Error creating event',
        creatingEventMsg: 'There was an issue creating the event',
        updatingEvent: 'Error updating event',
        updatingEventMsg: 'There was an issue updating the event',
        creatingTemplate: 'Error creating template',
        creatingTemplateMsg: 'There was an issue creating the template',
        updatingTemplate: 'Error updating template',
        updatingTemplateMsg: 'There was an issue updating the template',
      },
      info: {
        newEvent: 'New event',
        template: 'Template',
        eventTypeSelectionTitle: 'What would you like to create today?',
        eventTypeSelectionDescription:
          'You can create a new event and start organizing your tickets and details or create a template for future events to speed up your organization process.',
        eventFeatures: 'Event features',
        eventFeaturesDescription:
          'Edit, delete, and add the features needed to make your event.',
        noTemplateStats:
          'Event templates do not have any data or event metrics to showcase in the dashboard, so this section will be empty.',
        noTemplateStatsAlt: 'No template image',
        cardTemplatePlaning:
          'Templates will help you get a head start on your event organization, so you don’t have to create an event from scratch.',
        cardEventPlaning:
          'To get started, we need some general details about your event. This can be modified at a later date by going to event settings.',
        emptyState: 'First things first, it’s time to create your event.',
        noAttendees: 'No attendees yet',
        successUpdatingEventMsg: 'Your event settings have been saved.',
        successUpdatingTemplateMsg: 'Your template settings have been saved.',
        successTemplateCreatedTitle: 'Success! your event template was created',
        successFirstEventCreatedTitle: 'You created your first event!',
        successFirstTemplateCreatedTitle: 'You created your first template!',
        successEventCreatedTitle: 'Success! Your event was created',
        successEventTemplateDescription:
          'You can edit your template to add additional information or create another one.',
        successEventCreatedDescription:
          'To add or modify details about the event you can go to event settings, or if you want to start selling and promoting your event you can create tickets.',
        eventNumber: 'There are {{events}} events scheduled for this day.',
        eventCreated: 'Event was created',
        yourEvent: 'Your Event',
        deleteEventAlertTitle: 'Are you completely sure?',
        deleteEventInformativeText:
          'This action <1>cannot be undone</1>, by deleting <3>{{eventName}}</3> you will no longer be able to access this event information.',
        deleteEventHelperText: 'Type <1>Delete</1> to confirm',
      },
    },
    views: {
      calendarView: 'Calendar view',
      listView: 'List view',
    },
    forms: {
      category: 'Category',
      selectCategory: 'Please select a category',
      formats: 'Formats',
      hourFormat: 'Hour format',
      dateFormat: 'Date format',
      googleAnalytics: 'Google analytics',
      googleAnalyticsTrackingCode: 'Google analytics tracking code',
      googleTagManagerCode: 'Google tag manager tracking code',
      eventBanner: 'Event banner',
      eventLogo: 'Event logo',
      logoSuggestion: 'Suggested size: 1024 x 486px, max: 5MB.',
      bannerSuggestion: 'Suggested size: 1024 x 768px, max: 5MB.',
      eventUri: 'Event uri',
      eventUriHelper: 'Please do not use spaces or special characters',
      timezone: 'Timezone',
      selectTimezone: "Please select your event's timezone",
      virtualEvent: 'Virtual event',
      inPersonEvent: 'In person event',
      hybrid: 'Hybrid',
      name: 'Name of event',
      namePlaceholder: 'Ex: International Film Festival',
      templateNamePlaceholder: 'Ex: Templates for conference',
      templateName: 'Template name',
      startDate: 'Start date',
      closingDate: 'Closing date',
      language: 'Language',
      templateImage: 'Image for template',
      amPm: 'AM-PM',
      description: 'Description',
      hours24: '24 Hours',
      ddMmYyyy: 'dd/mm/yyyy',
      mMDdYyyy: 'mm/dd/yyyy',
      locationPlaceholder: 'Ex: Plaza Mayor, Medellin',
      location: 'Location',
      createEvent: 'Create event',
      createTemplate: 'Create template',
      moreSettings: 'More settings',
      availableLanguages: 'Available languages',
      defaultLanguage: 'Default language',
      allOptionsSelected:
        'You have added all the languages currently supported',
      addLanguage: 'Select a language to add',
      chooseDefaultLanguage: 'Select a default language',
    },
    modules: {
      title: 'Event modules',
      add: 'Add module',
      addPlural: 'Add modules',
      info: 'Add additional modules to your event and customize it',
      communications: 'Communications',
      communicationsInfo:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
      attendees: 'Attendees',
      attendeesInfo: 'Manage all your event attendees',
      agenda: 'Agenda',
      agendaInfo:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
      businessConferences: 'Business conferences',
      businessConferencesInfo:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
      stands: 'Stands',
      standsInfo:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
      surveys: 'Surveys',
      surveysInfo:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
      pois: 'Pois',
      poisInfo:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
      app: 'App',
      appInfo:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
      settings: 'Settings',
      settingsInfo:
        'Administer and manage the general information of your event',
      ticketTypes: 'Ticket types',
      ticketTypesInfo:
        'Manage the different types of registration for your assistants.',
      website: 'Website',
      websiteInfo:
        "Customize your event's website for registration, ticketing, etc.",
      paymentOptions: 'Payment Options',
      schedule: 'Schedule',
      invoice: 'Invoice',
      topSights: 'TopSights',
      networking: 'Networking',
      badges: 'Badges',
      globalStyles: 'Global Styles',
    },
    statuses: {
      active: 'Active',
      inactive: 'Inactive',
      all: 'All',
      past: 'Past',
      current: 'Current',
      templates: 'Templates',
    },
    attributes: {
      eventLogo: 'Event logo',
    },
  },
  es: {
    title: 'Eventos',
    templates: 'Plantillas',
    eventSettings: 'Event settings',
    createTemplateForm: 'Create template form',
    createEventForm: 'Create event form',
    templateSettings: 'Template settings',
    sidebar: {
      myEvents: 'Mis eventos',
      myEvent: 'Mi evento',
      dashboard: 'Dashboard',
      modules: 'Módulos',
    },
    actions: {
      createEvent: 'Crear evento',
      createTemplate: 'Crear plantilla',
      copyTemplate: 'Copy template',
      deleteTemplate: 'Delete template',
      copyEvent: 'Copiar evento',
      deleteEvent: 'Delete event',
      addLogo: 'Agregar logo a un evento',
      editLogo: 'Modificar logo',
      createEventTicket: 'Crear ticket de evento',
      editTemplate: 'Edit template',
      editEventSettings: 'Editar opciones del evento',
      copy: 'Copiar',
    },
    messages: {
      errors: {
        creatingEvent: 'Error creating event',
        creatingEventMsg: 'There was an issue creating the event',
        updatingEvent: 'Error updating event',
        updatingEventMsg: 'There was an issue updating the event',
        creatingTemplate: 'Error creating template',
        creatingTemplateMsg: 'There was an issue creating the template',
        updatingTemplate: 'Error updating template',
        updatingTemplateMsg: 'There was an issue updating the template',
      },
      info: {
        newEvent: 'New event',
        template: 'Template',
        eventTypeSelectionTitle: 'What would you like to create today?',
        eventTypeSelectionDescription:
          'You can create a new event and start organizing your tickets and details or create a template for future events to speed up your organization process.',
        eventFeatures: 'Event features',
        eventFeaturesDescription:
          'Edit, delete, and add the features needed to make your event.',
        noTemplateStats:
          'Event templates do not have any data or event metrics to showcase in the dashboard, so this section will be empty.',
        cardTemplatePlaning:
          'Templates will help you get a head start on your event organization, so you don’t have to create an event from scratch.',
        cardEventPlaning:
          'To get started, we need some general details about your event. This can be modified at a later date by going to event settings.',
        emptyState: 'First things first, it’s time to create your event.',
        noAttendees: 'Aún no hay asistentes',
        successUpdatingEventMsg: 'Your event settings have been saved.',
        successUpdatingTemplateMsg: 'Your template settings have been saved.',
        successTemplateCreatedTitle: 'Success! your event template was created',
        successFirstEventCreatedTitle: 'You created your first event!',
        successFirstTemplateCreatedTitle: 'You created your first template!',
        successEventCreatedTitle: 'Success! Your event was created',
        successEventTemplateDescription:
          'Puedes editar tu plantilla para agregar información adicional o crear otra.',
        successEventCreatedDescription:
          'Para agregar o modificar detalles sobre el evento puedes dirigirte a la configuracion del evento o si quieres empezar a vender y promocionar tu evento, puedes crear los tickets',
        eventNumber: 'There are {{events}} events scheduled for this day.',
        eventCreated: 'Evento creado en',
        yourEvent: 'tuEvento',
      },
    },
    views: {
      calendarView: 'Vista calendario',
      listView: 'Vista lista',
    },
    forms: {
      formats: 'Formats',
      hourFormat: 'Hour format',
      dateFormat: 'Date format',
      googleAnalytics: 'Google analytics',
      googleAnalyticsTrackingCode: 'Google analytics tracking code',
      googleTagManagerCode: 'Google tag manager tracking code',
      eventBanner: 'Event banner',
      eventLogo: 'Event logo',
      logoSuggestion: 'Suggested size: 1024 x 486px, max: 5MB.',
      bannerSuggestion: 'Suggested size: 1024 x 768px, max: 5MB.',
      eventUri: 'Event uri',
      eventUriHelper: 'Please do not use spaces or special characters',
      timezone: 'Timezone',
      selectTimezone: "Please select your event's timezone",
      virtualEvent: 'Virtual event',
      inPersonEvent: 'In person event',
      hybrid: 'Híbrido',
      name: 'Name of event',
      namePlaceholder: 'Ex: International Film Festival',
      templateNamePlaceholder: 'Ex: Templates for conference',
      templateName: 'Template name',
      startDate: 'Start date',
      closingDate: 'Closing date',
      language: 'Language',
      templateImage: 'Image for template',
      amPm: 'AM-PM',
      description: 'Description',
      hours24: '24 Hours',
      ddMmYyyy: 'dd/mm/yyyy',
      mMDdYyyy: 'mm/dd/yyyy',
      locationPlaceholder: 'Ex: Plaza Mayor, Medellin',
      location: 'Location',
      createEvent: 'Create event',
      createTemplate: 'Create template',
      moreSettings: 'More settings',
    },
    modules: {
      title: 'Módulos de mi evento',
      add: 'Agregar módulo',
      addPlural: 'Agregar módulos',
      info: 'Agrega módulos adicionales a tu evento y personalízalo a tu medida',
      communications: 'Comunicaciones',
      communicationsInfo:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
      attendees: 'Asistentes',
      attendeesInfo: 'Administra a todos los asistentes de tu evento.',
      agenda: 'Agenda',
      agendaInfo:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
      businessConferences: 'Rueda de negocios',
      businessConferencesInfo:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
      stands: 'Stands',
      standsInfo:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
      surveys: 'Encuestas',
      surveysInfo:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
      pois: 'Puntos de interés',
      poisInfo:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
      app: 'App',
      appInfo:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
      settings: 'Configuraciones',
      settingsInfo: 'Administra y gestiona la información general de tu evento',
      ticketTypes: 'Tipos de entrada',
      ticketTypesInfo:
        'Administra los diferentes tipos de registro para tus asistentes.',
      website: 'Sitio web',
      websiteInfo:
        'Personaliza el sitio web de tu evento para registros, venta de boletería, etc.',
    },
    statuses: {
      active: 'Activo',
      inactive: 'Inactivo',
      all: 'Todos',
      past: 'Pasados',
      current: 'Actuales',
    },
    attributes: {
      eventLogo: 'Logo del evento',
    },
  },
  fr: {},
  de: {},
};
