export default {
  en: {
    title: 'Emails',
    settings: 'Email settings',
    info: {
      createdByOn: 'Created by {{user}}. {{date}}',
    },
    messages: {
      emptyState:
        "You don't have any emails yet, to create one click on create email.",
      createdEmail: 'You created a new email!',
    },
    actions: {
      createEmail: 'Create email',
      editSettings: 'Edit settings',
      createAnotherEmail: 'Create another email',
      editEmail: 'Edit email',
      duplicate: 'Duplicate',
      deleteEmail: 'Delete email',
    },
    actionOptions: {
      blank: 'Select an action???', // TODO
      account_created: 'Account created',
      recover_password: 'Recover password',
      attendee_created: 'Attendee created',
      attendee_rejected: 'Attendee rejected',
      attendee_confirmed: 'Attendee confirmed',
      welcome_email: 'Welcome email',
    },
    templateOptions: {
      blank: 'Blank',
      essential: 'Essential',
      qrCode: 'QR code',
    },
    labels: {
      name: 'Email name',
      namePlaceholder: 'Name of email',
      description: 'Email description',
      availableFor: 'Available for the following case',
      template: 'Select a template you would like to start with',
      senderName: 'Sender name',
      senderNamePlaceholder: 'Ex: Get Togheter',
      senderEmail: 'Sender email',
      senderEmailPlaceholder: 'Ex: get-together@eventtia.com',
    },
  },
};
