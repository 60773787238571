export default {
  en: {
    homeText: 'Home',
    home: {},
    eventsText: 'Events',
    events: {
      showText: 'Event Name',
      attendeesText: 'Attendees',
      attendees: {
        newText: 'New Attendee',
        showText: 'Attendee Name',
        editText: 'Edit',
        infoText: 'Information',
        edit: {
          emailsText: 'Emails',
          activitiesText: 'Activities',
          paymentsText: 'Payments',
          networkingText: 'Networking',
        },
      },
      dashboardText: 'Dashboard',
      settingsText: 'Settings',
      emailsText: 'Emails',
      emails: {
        newText: 'New',
      },
      ticketsText: 'Ticket Types',
      tickets: {
        showText: 'Ticket type name',
        infoText: 'General Information',
        registrationText: 'Registration Form',
        advancedText: 'Advanced Options',
      },
      widgetText: 'Widget',
      modulesText: 'Modules',
      paymentsText: 'Payments',
    },
    teamText: 'My Team',
    team: {
      usersText: 'Users',
      rolesText: 'Roles',
      categoriesText: 'Categories',
      users: {
        newText: 'New user',
      },
      categories: {
        newText: 'New category',
      },
    },
    profileText: 'My account',
    profile: {
      infoText: 'Info',
      securityText: 'Security',
      subscriptionText: 'Subscription',
      subscription: {
        plansText: 'Plans',
        successText: 'Success payment',
        failedText: 'Failed payment',
      },
    },
  },
  es: {},
  fr: {},
};
