export default {
  en: {
    ariaLabel: 'signUp form,',
    actions: {
      register: 'Register',
      verify: 'Verify account',
      backToLogin: 'Back to login',
      createAccount: 'Create account',
    },
    title: {
      registration: 'Registration',
    },
    formError: {
      enterValidEmail: 'Enter a valid email.',
      requiredField: 'The field is required.',
      invalidToken: 'The token you entered is invalid.',
      invalidEmail: 'The email you provided is invalid.',
      invalidPassword:
        'Use at least one upper case letter, one lowercase letter, one number and 8 characters length',
      errorPasswordMatch: "The passwords don't match",
      errorPasswordValidation:
        'Your password must have at least one uppercase letter, one lowercase letter, one number and must be at least 8 characters long',
    },
    form: {
      firstName: 'First name',
      lastName: 'Last name',
      company: 'Company',
      email: 'Email',
      phone: 'Phone',
      accessToken: 'Access token',
      password: 'Password',
      confirmPassword: 'Confirm Password',
    },
    formPlaceHolder: {
      firstName: 'Ex: John',
      lastName: 'Ex: Smith',
      company: 'Ex: Eventtia',
      email: 'Ex: hello@eventtia',
      phone: 'Ex: +01 123 456 7899',
      password: '***********',
    },
    messages: {
      alreadyHaveAccount: 'Already have an account?',
      logIn: 'Log in',
      singUpThanksTitle: 'Thank you for signing up!',
      singUpThanksDescription:
        'Before you can start using Eventtia, you will receive an email to verify your account. As soon as you complete your verification you can log in and begin planning your event!',
    },
    verifyPage: {
      WelcomeTitle: 'Welcome to Eventtia!',
      verifyDescription: 'Hi {{username}} !',
      verifyDescription2:
        "We're excited you've decided to join us, before we get started we need to verify your account so that you can begin planning your event.",
    },
  },
  es: {},
  fr: {},
};
