export default {
  en: {
    actions: {
      edit: 'Edit',
      createUser: 'Create user',
      editUser: 'Edit user',
      removeUser: 'Remove user',
      createAnotherUser: 'Create another user',
      createRole: 'Create role',
      editRole: 'Edit role',
      createAnotherRole: 'Create another role',
      createCategory: 'Create category',
      editCategory: 'Edit category',
      createAnotherCategory: 'Create another category',
    },
    sections: {
      users: 'Users',
      roles: 'Roles',
      categories: 'Categories',
    },
    messages: {
      firstUser:
        "Looks like you're the only one in your team right now!\nIf you want to add more team members, you can <1>create a user.</1>",
      userCreated: 'You created a new user!',
      deleteUserConfirmation:
        "Are you sure you want to delete this user? This action can't be undone.",
      deleteRoleConfirmation:
        "Are you sure you want to delete this role? This action can't be undone.",
      categoryCreated: 'You have created a category!',
      roleCreated: 'You created a new role!',
    },
    user: {
      name: 'Name',
      firstName: 'First name',
      lastName: 'Last name',
      role: 'Role',
      email: 'Email',
      phone: 'Phone',
      lastSignInAt: 'Last log in date',
      accessToAllEvents: 'Access to all events',
      neverLoggedIn: 'Never',
    },
    category: {
      categoryName: 'Category name',
      description: 'Description',
      events: 'Events',
      users: 'Users in this category',
      creationDate: 'Creation date',
    },
    forms: {
      firstNamePlaceholder: 'John',
      lastNamePlaceholder: 'Doe',
      emailPlaceholder: 'hello@eventtia.com',
      phonePlaceholder: '+1 123 456 7890',
      accessToAllEventsDescription:
        'If this option is not selected, the user will only have access to events they create or those assigned to them through an area.',
      categoryNamePlaceholder: 'Art and science',
      roleNamePlaceholder: 'Ex: Event Manager',
    },
    roles: {
      roleName: 'Role name',
      rolePermissionDescription: 'Select the permissions for this role',
      users: 'Users in this role',
      date: 'Created on',
    },
    permissions: {
      allowedTo: 'Allowed to',
      actions: {
        index: 'Index',
        show: 'Show',
        create: 'Create',
        update: 'Update',
        destroy: 'Destroy',
      },
      moduleTypes: {
        attendeeTypes: 'Attendee types',
        attendees: 'Attendees',
        eventSettings: 'Event settings',
        events: 'Events',
        exportProcesses: 'Export processes',
        fields: 'Fields',
        notifications: 'Notifications',
      },
    },
    addUsersModal: {
      addUsersToCategory: 'Add users to this category',
      helperText:
        'Users you add will be able to use their permissions in events of this category.',
      addUsers: 'Add users',
      cancel: 'Cancel',
      inputPlaceholder: 'Min two characters',
      noResultsLabel: 'No users found',
      usersLabel: 'users',
    },
    table: {
      usersInThisCategory: 'Users in this category',
      userInThisCategory: 'User in this category',
    },
  },
};
