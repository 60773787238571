export default {
  en: {
    tabs: {
      info: 'General information',
      security: 'Security',
      subscription: 'Subscription',
    },
    forms: {
      currentPassword: 'Current password',
      newPassword: 'New password',
      confirmPassword: 'Confirm new password',
      passwordPlaceholder: '********',
    },
    activation: {
      title: 'Activation account',
      informativeText:
        'You are currently logged in. Activating this account will log you out. Do you wish to activate it now?',
      buttons: {
        later: 'Later',
        now: 'Activate now',
      },
    },
  },
  es: {
    tabs: {
      general: 'Información general',
      security: 'Seguridad',
      subscription: 'Subscripciones',
    },
    forms: {
      currentPassword: 'Contraseña actual',
      newPassword: 'Nueva contraseña',
      confirmPassword: 'Confirmar nueva contraseña',
      passwordPlaceholder: '********',
    },
  },
};
