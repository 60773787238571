import React from 'react';
import { Navigate } from 'react-router-dom';
import AsyncPage from '../hocs/AsyncPage';
import AsyncLayout from '../hocs/AsyncLayout';

import AttendeesFilters from '../components/AttendeesFilters';
import EventsFilters from '../components/EventFilters';
import { LOCALE_PATH } from '.';

const loggedInRoutesConfig = [
  {
    element: <AsyncLayout page="LoggedInLayout" />,
    children: [
      {
        element: <AsyncLayout page="MainSectionLayout" />,
        children: [
          {
            path: '*',
            element: <Navigate to="events" />,
          },
          {
            path: 'events/*',
            element: <AsyncPage page="EventsIndex" />, // must have Outlet for rendering drawers
            children: [
              // these are drawers
              {
                path: 'new',
                element: <AsyncPage page="EventCreate" />,
              },
              {
                path: 'filter',
                element: <EventsFilters />,
              },
            ],
          },
          {
            path: 'team/*',
            element: <AsyncPage page="TeamIndex" />, // must have Outlet for rendering sections
            children: [
              // these are drawers
              {
                path: '*',
                element: <Navigate to="users" />,
              },
              {
                path: 'users',
                element: <AsyncPage page="Users" />,
                children: [
                  {
                    path: 'new',
                    element: <AsyncPage page="UserCreate" />,
                  },
                ],
              },
              {
                path: 'roles',
                element: <AsyncPage page="Roles" />,
                children: [
                  {
                    path: 'new',
                    element: <AsyncPage page="RoleCreate" />,
                  },
                ],
              },
              {
                path: 'categories',
                element: <AsyncPage page="Categories" />,
                children: [
                  {
                    path: 'new',
                    element: <AsyncPage page="CategoryCreate" />,
                  },
                  {
                    path: ':categoryId',
                    element: <AsyncPage page="CategoryEdit" />,
                  },
                ],
              },
              // {
              //   path: 'roles',
              //   element: 'roles',
              // },
              // {
              //   path: 'areas',
              //   element: 'areas',
              // },
            ],
          },
        ],
      },
      {
        element: <AsyncLayout page="MainSectionModuleLayout" />,
        children: [
          {
            path: 'profile/*',
            element: <AsyncPage page="MyAccount" />,
            children: [
              {
                path: '*',
                element: <Navigate to="info" />,
              },
              {
                path: 'info',
                element: <AsyncPage page="AccountEdit" />,
              },
              {
                path: 'security',
                element: <AsyncPage page="AccountSecurity" />,
              },
              {
                path: 'subscription',
                element: <AsyncPage page="AccountSubscription" />,
                children: [
                  {
                    path: 'plans',
                    element: <AsyncPage page="UserPlans" />,
                  },
                  {
                    path: 'success',
                    element: <AsyncPage page="SuccessPayment" />,
                  },
                  {
                    path: 'failed',
                    element: <AsyncPage page="FailedPayment" />,
                  },
                  {
                    path: 'onboarding-success',
                    element: <AsyncPage page="OnboardingSuccess" />,
                  },
                  {
                    path: 'onboarding-fail',
                    element: <AsyncPage page="OnboardingFail" />,
                  },
                ],
              },
            ],
          },
        ],
      },
      // alternative layout, this have grey background
      {
        element: <AsyncLayout page="MainSectionModuleLayout" alternative />,
        children: [
          {
            path: 'team/users/:userId',
            element: <AsyncPage page="UserEdit" />,
          },
          {
            path: 'team/categories/:categoryId',
            element: <AsyncPage page="CategoryEdit" />,
          },
          {
            path: 'team/roles/:roleId',
            element: <AsyncPage page="RoleEdit" />,
          },
        ],
      },
      // event show and event module indexes
      {
        path: 'events/:eventUri/*',
        element: <AsyncLayout page="EventLayout" />,
        children: [
          {
            path: '*',
            element: <Navigate to="dashboard" />,
          },
          {
            path: 'dashboard',
            element: <AsyncPage page="EventDashboard" />,
          },
          {
            path: 'widget',
            element: <AsyncPage page="WidgetSettings" />,
          },
          {
            path: 'tickets/*',
            element: <AsyncPage page="TicketTypesIndex" />, // must have outlet for rendering drawer
            children: [
              // TODO: drawers
              {
                path: 'new',
                element: <AsyncPage page="TicketTypeCreate" />, // this is the drawer
              },
              {
                path: 'fields',
                element: <AsyncPage page="FieldsLibrary" />, // this is the drawer
                children: [
                  {
                    path: 'new', // this is the drawer
                    element: <AsyncPage page="FieldsCreate" />,
                  },
                  {
                    path: ':fieldId/edit', // this is the drawer
                    element: <AsyncPage page="FieldEdit" />,
                  },
                ],
              },
            ],
          },
          {
            path: 'attendees/*',
            element: <AsyncPage page="AttendeesIndex" />, // this must have an outlet that will render drawers
            children: [
              // these are drawers
              // TODO: drawers
              {
                path: 'new', // this is the drawer
                element: <AsyncPage page="AttendeeCreate" />,
              },
              {
                path: 'filter',
                element: <AttendeesFilters />,
              },
            ],
          },
          {
            path: 'emails/*',
            element: <AsyncPage page="EventEmails" />,
            children: [
              // these are drawers
              {
                path: 'new',
                element: <AsyncPage page="EventEmailCreate" />,
              },
              {
                path: 'settings',
                element: <AsyncPage page="EventEmailSettings" />,
              },
            ],
          },
          {
            path: 'payments',
            element: <AsyncPage page="Payments" />,
          },
          {
            path: 'settings',
            element: <AsyncPage page="EventSettings" />,
          },
          // TODO: ...speakers, activities, etc
        ],
      },
      {
        element: <AsyncLayout page="ModuleShowLayout" />,
        children: [
          {
            // ticket show <- aqui hay problema???
            path: 'events/:eventUri/tickets/:ticketId/*',
            element: <AsyncPage page="TicketTypeShow" />, // TODO: should this be moved to layouts?
            children: [
              {
                path: '*',
                element: <Navigate to="info" />,
              },
              {
                path: 'info',
                element: <AsyncPage page="TicketTypeEdit" />,
              },
              {
                path: 'registration',
                element: <AsyncPage page="TicketTypeRegistration" />,
                children: [
                  {
                    path: 'add-field',
                    element: <AsyncPage page="FieldsLibrary" />,
                    children: [
                      {
                        path: 'new', // this is the drawer
                        element: <AsyncPage page="FieldsCreate" />,
                      },
                      {
                        path: ':fieldId/edit', // this is the drawer
                        element: <AsyncPage page="FieldEdit" />,
                      },
                    ],
                  },
                ],
              },
              {
                path: 'advanced',
                element: <AsyncPage page="TicketTypeAdvancedOptions" />,
                // TODO: drawers
              },
            ],
          },
          {
            // attendee show <- aqui hay problema???
            path: 'events/:eventUri/attendees/:attendeeId/*',
            element: <AsyncPage page="AttendeeShow" />, // this must have an Outlet that will render for each tab
            children: [
              // these are the content inside each tab
              {
                path: '*',
                element: <Navigate to="info" />,
              },
              {
                path: 'info',
                element: <AsyncPage page="AttendeeEdit" />,
              },
              {
                path: 'emails',
                element: <div>emails component</div>,
              },
              {
                path: 'activities',
                element: <div>activities component</div>,
              },
              {
                path: 'payments',
                element: <div>payments component</div>,
                children: [
                  // these are drawers
                  // TODO: drawers
                  {
                    path: 'new', // this is the drawer
                    element: <div>new payment drawer</div>,
                  },
                ],
              },
              {
                path: 'networking',
                element: <div>networking component</div>,
              },
            ],
          },
          {
            path: 'events/:eventUri/emails/:emailId/*',
            element: <AsyncPage page="EventEmailShow" />, // this must have an Outlet that will render for each tab
          },
        ],
      },
    ],
  },
  {
    element: <AsyncLayout page="LoggedOutLayout" />,
    children: [
      {
        path: 'activate-account/:activationToken',
        element: <AsyncPage page="ActivateAccount" />,
      },
    ],
  },
];

const loggedOutRoutesConfig = [
  {
    element: <AsyncLayout page="LoggedOutLayout" />,
    children: [
      {
        path: 'login',
        element: <AsyncPage page="Login" />,
      },
      {
        path: 'sign-up',
        element: <AsyncPage page="SignUp" />,
      },
      {
        path: 'activate-account/:activationToken',
        element: <AsyncPage page="ActivateAccount" />,
      },
      {
        path: 'forgot-password',
        element: <AsyncPage page="ForgotPassword" />,
      },
      {
        path: 'change-password/:token',
        element: <AsyncPage page="ChangePassword" />,
      },
      {
        path: 'set-password/:token',
        element: <AsyncPage page="ChangePassword" isNewUser />,
      },
      {
        path: '*',
        element: <Navigate to="login" />,
      },
      // this will not be in this flow
      // {
      //   path: 'payment-form',
      //   element: <AsyncPage page="PaymentForm" />,
      // },
    ],
  },
];

const getFullPaths = (routes, prefix) => {
  const result = [];
  routes.forEach(({ path = '', children }) => {
    const suffix = path.endsWith('*') ? '' : '/';
    const currentPath = `${prefix}${path.replace('*', '')}${suffix}`.replace(
      '//',
      '/'
    );
    result.push({ path: currentPath });
    if (children) result.push(...getFullPaths(children, currentPath));
  });
  return result;
};

const paths = [
  ...getFullPaths(loggedOutRoutesConfig, `/${LOCALE_PATH}`),
  ...getFullPaths(loggedInRoutesConfig, `/${LOCALE_PATH}`),
];

export { loggedInRoutesConfig, loggedOutRoutesConfig, paths };
