const TOKEN_KEY = 'token';

export const setToken = (token) => localStorage.setItem(TOKEN_KEY, token);

export const removeToken = () => localStorage.removeItem(TOKEN_KEY);

export const loggedIn = () => !!localStorage.getItem(TOKEN_KEY);

export const getToken = () => localStorage.getItem(TOKEN_KEY);

export const getTokenContent = () => {
  const token = localStorage.getItem(TOKEN_KEY);
  const [, payload] = token.split('.');

  return JSON.parse(atob(payload));
};
