export default {
  en: {
    latestPayment: 'Latest payment',
    plan: 'Plan',
    active: 'Active',
    pastDue: 'Past due',
    date: 'Date',
    amount: 'Amount',
    dueOn: 'Due on',
    paymentOptions: 'Payment options',
    successPayment: 'Successful subscription!',
    onboardingSuccess: 'Successful onboarding!',
    onboardingFail: 'Fail onboarding!',
    failedPayment: 'The subscription could not be complete',
    actions: {
      goBack: 'Go back',
      choosePlan: 'Choose plan',
      changePlan: 'Change plan',
      managePayments: 'Manage payments',
      stripeOnboarding: 'Stripe onboarding',
      clickHere: 'Click here to set it up',
    },
    info: {
      successPaymentText:
        'Congratulations, you now have a new subscription. Click the button below to view it.',
      onboardingSuccessText:
        'You have completed your stripe onboarding. You will be now able to receive payments.',
      onboardingFailedText: 'Something has gone wrong in the onboarding.',
      noPaymentEnabled:
        "You haven't completed your stripe onboarding. You will not be able to receive payments until you finish this. <1>Click here to set it up</1>",
      failedPaymentText:
        'Something has gone wrong in the subscription payment.',
      noSubscriptionText:
        "You don't have an active plan. Please choose one by clicking the button bellow",
      activatingPlan:
        'We are activating your plan, this may take a few seconds',
      cardChecks: {
        checkIn: 'Check in',
        sponsors: 'Sponsors',
        editor: 'Website editor',
        registrations: 'Included registrations',
      },
    },
    currency: 'Currency',
    vatAlias: 'Tax denomination',
    taxValue: 'Tax cost',
    eventBudget: 'Event budget',
    secretApiKey: 'Secret Api key',
    publishableApiKey: 'Publishable api key',
    apiKey: 'Api key',
    apiLogin: 'Api login',
    merchantId: 'Merchant id',
    accountId: 'Account id',
    testMode: 'Test mode',
    productionKey: 'Production key',
    sandboxKey: 'Sandbox key',
    messages: {
      errors: {
        updatingPayments: 'Error updating payment options',
        updatingPaymentsMsg: 'There was an issue updating payment options',
      },
    },
  },
  es: {
    latestPayment: 'Último pago',
    plan: 'Plan',
    active: 'Activo',
    pastDue: 'Vencido',
    date: 'Fecha',
    amount: 'Monto',
    dueOn: 'Vencimiento',
    paymentOptions: 'Opciones de pago',
    successPayment: '¡Suscripción exitosa!',
    failedPayment: 'La suscripción no se pudo completar',
    actions: {
      goBack: 'Volver',
      choosePlan: 'Elegir plan',
      changePlan: 'Cambiar plan',
      managePayments: 'Gestionar pagos',
    },
    info: {
      successPaymentText:
        'Felicidades, ahora tienes una nueva suscripción. Click en el botón acontinuación para verla.',
      failedPaymentText: 'Algo ha salido mal en el pago de la suscripción.',
      noSubscriptionText:
        'No tienes un plan activo. Elija uno haciendo clic en el botón de abajo.',
      activatingPlan:
        'Estamos activando tu plan, esto puede tomar unos segundos.',
    },
    currency: 'Moneda',
    vatAlias: 'Denominación de impuesto',
    taxValue: 'Valor del impuesto',
    eventBudget: 'Event budget',
    secretApiKey: 'Secret Api key',
    publishableApiKey: 'Publishable api key',
    apiKey: 'Api key',
    apiLogin: 'Api login',
    merchantId: 'Merchant id',
    accountId: 'Account id',
    testMode: 'Modo de prueba',
    productionKey: 'Production key',
    sandboxKey: 'Sandbox key',
    messages: {
      errors: {
        updatingPayments: 'Error updating payment options',
        updatingPaymentsMsg: 'There was an issue updating payment options',
      },
    },
  },
  fr: {},
  de: {},
};
