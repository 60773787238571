import PropTypes from 'prop-types';

const relationship = PropTypes.oneOfType([
  PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
    })
  ),
  PropTypes.shape({
    id: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
  }),
]);

const formErrors = PropTypes.objectOf(
  PropTypes.shape({
    type: PropTypes.string,
    message: PropTypes.string,
    ref: PropTypes.shape({
      type: PropTypes.string,
    }),
  })
);

const reaction = PropTypes.shape({
  emoji: PropTypes.string,
  name: PropTypes.string,
  uuid: PropTypes.string,
});

const message = PropTypes.shape({
  uuid: PropTypes.string,
  body: PropTypes.string,
  attendee_id: PropTypes.string,
  username: PropTypes.string,
  avatar: PropTypes.string,
  timestamp: PropTypes.string,
});

const question = PropTypes.shape({
  uuid: PropTypes.string,
  body: PropTypes.string,
  timestamp: PropTypes.string,
});

const event = PropTypes.shape({
  id: PropTypes.string,
  name: PropTypes.string,
  eventUri: PropTypes.string,
  whiteLabel: PropTypes.bool,
  description: PropTypes.string,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  location: PropTypes.shape({
    address: PropTypes.string,
    country: PropTypes.string,
    city: PropTypes.string,
    coordinates: PropTypes.shape({
      lat: PropTypes.string,
      lng: PropTypes.string,
    }),
  }),
  defaultLanguage: PropTypes.string,
  timeFormat: PropTypes.string,
  dateFormat: PropTypes.string,
  isVirtual: PropTypes.string,
  googleAnalyticsTrackingCode: PropTypes.string,
  googleTagManagerCode: PropTypes.string,
  timezone: PropTypes.string,
  logo: PropTypes.shape({
    filename: PropTypes.string,
    medium: PropTypes.string,
  }),
  banner: PropTypes.shape({
    filename: PropTypes.string,
    medium: PropTypes.string,
  }),
  isTemplate: PropTypes.bool,
  totalAttendees: PropTypes.number,
});

const appSettings = PropTypes.shape({
  id: PropTypes.string,
  darkTheme: PropTypes.bool,
  waitingImage: PropTypes.shape({
    filename: PropTypes.string,
    large: PropTypes.string,
  }),
  languageSelectorEnabled: PropTypes.bool,
  backgroundImage: PropTypes.shape({
    filename: PropTypes.string,
    large: PropTypes.string,
  }),
  icon: PropTypes.shape({
    filename: PropTypes.string,
    thumb: PropTypes.string,
  }),
  primaryColor: PropTypes.string,
  secondaryColor: PropTypes.string,
  logo: PropTypes.shape({
    filename: PropTypes.string,
    medium: PropTypes.string,
    small: PropTypes.string,
  }),
  modules: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.shape({
        en: PropTypes.string,
        es: PropTypes.string,
        fr: PropTypes.string,
      }),
      type: PropTypes.string,
      enabled: PropTypes.bool,
      customParams: PropTypes.shape({
        content: PropTypes.string,
        icon: PropTypes.string,
      }),
    })
  ),
});

const meeting = PropTypes.shape({
  id: PropTypes.string,
  guid: PropTypes.string,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  host: PropTypes.shape({
    id: PropTypes.string,
  }),
  participant: PropTypes.shape({
    id: PropTypes.string,
  }),
  businessConferenceId: PropTypes.number,
  status: PropTypes.number,
  userMessage: PropTypes.string,
});

const user = PropTypes.shape({
  id: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  email: PropTypes.string,
  accountName: PropTypes.string,
  account: relationship,
});

const attendee = PropTypes.shape({
  id: PropTypes.string,
  photoUrl: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  email: PropTypes.string,
  telephone: PropTypes.string,
  company: PropTypes.string,
  fieldsData: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
      PropTypes.shape({
        filename: PropTypes.string,
      }),
    ])
  ),
  attendeeType: PropTypes.shape({
    id: PropTypes.string,
  }),
  paid: PropTypes.bool,
  createdAt: PropTypes.string,
  createdBy: PropTypes.string,
  updatedAt: PropTypes.string,
  status: PropTypes.string,
});

const attendeeType = PropTypes.shape({
  id: PropTypes.string,
  allowPublicRegistration: PropTypes.bool,
  confirmationRequired: PropTypes.bool,
  description: PropTypes.objectOf(PropTypes.string),
  limit: PropTypes.number,
  name: PropTypes.objectOf(PropTypes.string),
  price: PropTypes.number,
  validIfNoPayments: PropTypes.bool,
});

const eventSettings = PropTypes.shape({
  id: PropTypes.string,
  currency: PropTypes.string,
});

const customField = PropTypes.shape({
  id: PropTypes.string,
  default: PropTypes.bool,
  name: PropTypes.objectOf(PropTypes.string),
  type: PropTypes.string,
  order: PropTypes.number,
  display: PropTypes.bool,
  required: PropTypes.bool,
  filterable: PropTypes.bool,
  validations: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.number])
  ),
});

const participant = PropTypes.shape({
  id: PropTypes.string,
  profileContactName: PropTypes.string,
  profileDisplayName: PropTypes.string,
  blockedSlots: PropTypes.arrayOf(PropTypes.string),
});

const participantType = PropTypes.shape({
  id: PropTypes.string,
  canRequestMeetingToAttendeeTypes: PropTypes.arrayOf(PropTypes.number),
  agenda: PropTypes.objectOf(
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number]))
  ),
});

const survey = PropTypes.shape({
  id: PropTypes.string,
  description: PropTypes.string,
  name: PropTypes.string,
});

const workshop = PropTypes.shape({
  id: PropTypes.string,
  guid: PropTypes.string,
  name: PropTypes.string,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  description: PropTypes.string,
  streamingType: PropTypes.string,
  streamingUrl: PropTypes.string,
  streamingEmbedCode: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      en: PropTypes.string,
      es: PropTypes.string,
      fr: PropTypes.string,
    }),
    PropTypes.arrayOf(
      PropTypes.shape({
        displayName: PropTypes.string,
        embedCode: PropTypes.string,
      })
    ),
  ]),
  speakers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
    })
  ),
  enabledEmotions: PropTypes.bool,
});

const attendeeWorkshops = PropTypes.shape({
  attendee: PropTypes.shape({
    id: PropTypes.string,
  }),
  workshop: PropTypes.shape({
    id: PropTypes.string,
  }),
});

const workshopCategory = PropTypes.shape({
  id: PropTypes.string,
  name: PropTypes.string,
});

const eventFile = PropTypes.shape({
  id: PropTypes.string,
  name: PropTypes.string,
  eventFile: PropTypes.string,
  entityType: PropTypes.string,
  entityId: PropTypes.number,
});

const speaker = PropTypes.shape({
  fullName: PropTypes.string,
  company: PropTypes.string,
  position: PropTypes.string,
  picture: PropTypes.shape({
    filename: PropTypes.string,
    small: PropTypes.string,
  }),
});

const note = PropTypes.shape({
  body: PropTypes.string,
  reviewed: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
});

const channel = PropTypes.shape({
  topic: PropTypes.string,
  name: PropTypes.string,
  avatar: PropTypes.string,
  type: PropTypes.oneOf(['private', 'group', 'announcement']),
});

const filter = PropTypes.oneOfType([
  PropTypes.string,
  PropTypes.number,
  PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
  PropTypes.shape({}),
]);

const entities = PropTypes.objectOf(
  PropTypes.objectOf(
    PropTypes.shape({
      id: PropTypes.string,
    })
  )
);

const conference = PropTypes.shape({
  id: PropTypes.string,
  meetingRejectionEnabled: PropTypes.bool,
  meetingRejectionDescriptionOptions: PropTypes.arrayOf(PropTypes.string),
});

const meta = PropTypes.objectOf(
  PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.number,
    PropTypes.string,
    PropTypes.shape({}),
    PropTypes.arrayOf(
      PropTypes.oneOfType([
        PropTypes.bool,
        PropTypes.number,
        PropTypes.string,
        PropTypes.shape({}),
      ])
    ),
  ])
);

const sponsor = PropTypes.shape({
  logo: PropTypes.shape({
    filename: PropTypes.string,
    medium: PropTypes.string,
  }),
  bannerImage: PropTypes.shape({
    filename: PropTypes.string,
    medium: PropTypes.string,
  }),
  id: PropTypes.string,
  fileCategoryId: PropTypes.number,
  description: PropTypes.string,
  name: PropTypes.string,
  website: PropTypes.string,
  category: PropTypes.shape({
    id: PropTypes.string,
  }),
});

const errors = PropTypes.objectOf(PropTypes.arrayOf(PropTypes.string));

const pricingTier = PropTypes.shape({
  id: PropTypes.string,
  attributes: PropTypes.shape({
    price: PropTypes.string,
    startDate: PropTypes.string,
    endDate: PropTypes.string,
    entityId: PropTypes.number,
  }),
});

const field = PropTypes.shape({
  id: PropTypes.string,
  default: PropTypes.bool,
  name: PropTypes.objectOf(PropTypes.string),
});

const formField = PropTypes.shape({
  order: PropTypes.number,
});

const commercialPlan = PropTypes.shape({
  active: PropTypes.string,
  archived: PropTypes.bool,
  description: PropTypes.string,
  id: PropTypes.string,
  interval: PropTypes.string,
  name: PropTypes.objectOf(PropTypes.string),
  prices: PropTypes.arrayOf(
    PropTypes.shape({
      currency: PropTypes.string,
      id: PropTypes.string,
      interval: PropTypes.string,
      value: PropTypes.string,
    })
  ),
  settings: PropTypes.shape({
    apiEnabled: PropTypes.bool,
    checkInEnabled: PropTypes.bool,
    customUrlsEnabled: PropTypes.bool,
    includedRegistrations: PropTypes.number,
    mailingEnabled: PropTypes.bool,
    mobileAppEnabled: PropTypes.bool,
    multiEventWorkshopEnabled: PropTypes.bool,
    multiLingualEnabled: PropTypes.bool,
    registrationEnabled: PropTypes.bool,
    sponsorsEnabled: PropTypes.bool,
    ssoEnabled: PropTypes.bool,
    studioEnabled: PropTypes.bool,
    surveysEnabled: PropTypes.bool,
    websiteEditorEnabled: PropTypes.bool,
    whiteLabel: PropTypes.bool,
  }),
});

const accountSubscription = PropTypes.shape({
  archived: PropTypes.bool,
  cancelAt: PropTypes.string,
  commercialPlan: PropTypes.objectOf(
    PropTypes.shape({
      id: PropTypes.string,
      type: PropTypes.string,
    })
  ),
  id: PropTypes.string,
  settings: PropTypes.objectOf(
    PropTypes.shape({
      apiEnabled: PropTypes.bool,
      checkInEnabled: PropTypes.bool,
      customUrlsEnabled: PropTypes.bool,
      includedRegistrations: PropTypes.number,
      mailingEnabled: PropTypes.bool,
      mobileAppEnabled: PropTypes.bool,
      multiEventWorkshopEnabled: PropTypes.bool,
      multiLingualEnabled: PropTypes.bool,
      registrationEnabled: PropTypes.bool,
      sponsorsEnabled: PropTypes.bool,
      ssoEnabled: PropTypes.bool,
      studioEnabled: PropTypes.bool,
      surveysEnabled: PropTypes.bool,
      websiteEditorEnabled: PropTypes.bool,
      whiteLabel: PropTypes.bool,
    })
  ),
  status: PropTypes.string,
  stripeIdentifier: PropTypes.string,
  stripeUrl: PropTypes.string,
  subscriptionDate: PropTypes.string,
  updatedById: PropTypes.number,
});

const accounts = PropTypes.shape({
  id: PropTypes.string,
  name: PropTypes.string,
  website: PropTypes.string,
  ip: PropTypes.string,
  country: PropTypes.string,
  latitude: PropTypes.string,
  longitude: PropTypes.string,
  uuid: PropTypes.string,
  activationCode: PropTypes.string,
  activationDate: PropTypes.string,
  active: PropTypes.bool,
  level: PropTypes.string,
  paymentsEnabled: PropTypes.bool,
});

const permission = PropTypes.shape({
  name: PropTypes.string,
  attendeeTypes: PropTypes.objectOf(PropTypes.string),
  attendees: PropTypes.objectOf(PropTypes.string),
  eventSettings: PropTypes.objectOf(PropTypes.string),
  events: PropTypes.objectOf(PropTypes.string),
  exportProcesses: PropTypes.objectOf(PropTypes.string),
  fields: PropTypes.objectOf(PropTypes.string),
  notifications: PropTypes.objectOf(PropTypes.string),
});

export default {
  accounts,
  formErrors,
  reaction,
  message,
  question,
  conference,
  event,
  appSettings,
  meeting,
  filter,
  attendee,
  participant,
  participantType,
  survey,
  workshop,
  attendeeWorkshops,
  workshopCategory,
  eventFile,
  speaker,
  note,
  channel,
  entities,
  meta,
  sponsor,
  relationship,
  attendeeType,
  customField,
  errors,
  eventSettings,
  pricingTier,
  field,
  user,
  formField,
  commercialPlan,
  accountSubscription,
  permission,
};
